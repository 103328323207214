export const pattern = {
  money: /^\d+(\.\d{0,2})?$/,
  area: /^\d+(\.\d{0,2})?$/,
  integer: /^\d+$/,
  phone: /^1\d{10}$/,
  telephone: /^\d{3,4}-\d{6,8}$/,
}

export const mobilePhoneReg = /^1\d{10}$/
export function isMobilePhone(phone: string) {
  return mobilePhoneReg.test(phone)
}

export const telephoneReg = /^\d{3,5}-\d{7,8}$/
export function isTelephone(phone: string) {
  if (phone) {
    return telephoneReg.test(phone)
  } else {
    return
  }
}

export const telephoneWithNationCodeReg = /^0086-\d{3,5}-\d{7,8}$/
export function isTelephoneWithNationCode(phone: string) {
  return telephoneWithNationCodeReg.test(phone)
}

export const phoneReg = /^0086-\d{3,5}-\d{7,8}$|^1\d{10}$/

export function removeNationCodeFromTelephone(phone: string) {
  if (typeof phone === 'string' && phone.length > 0) {
    return phone.replace(/^0086-/, '')
  }
  return phone
}

export function supplementNationCodeForTelephone(phone: string) {
  const phoneStr = String(phone || '')
  if (phoneStr.match(/^0086-/)) {
    return phoneStr
  } else {
    return `0086-${phoneStr}`
  }
}

export const acceptFile =
  /.txt$|.doc$|.docx$|.xls$|.xlsx$|.jpg$|.jpeg$|.png$|.bmp$|.pdf$|.rar$|.zip/
export function isAcceptFile(fileName: string) {
  return acceptFile.test(fileName.toLowerCase())
}

export const acceptVideoFile = [
  'video/mp4',
  'video/avi',
  'video/x-ms-wmv',
  'video/x-flv',
  'video/3gpp',
  'video/quicktime',
  'video/vnd.rn-realmedia',
]
export function isAcceptVideoFile(type: string) {
  return acceptVideoFile.includes(type)
}

export function targetTypeValidator(rule: any, value: any, callback: any) {
  if (value instanceof Array && value.length === 2) {
    const [fType, sType] = value
    if (fType && !sType) {
      callback(new Error('请选择完整的状态'))
    } else {
      callback()
    }
  } else {
    callback(new Error('请选择状态'))
  }
}

export const AuctionPlatform = [
  {
    id: 1,
    platformType: 'TAOBAO',
    name: '淘宝司法拍卖',
    patterns: [
      /^http(s)?:\/\/sf-item\.taobao\.com\/.*$/,
      /^http(s)?:\/\/susong-item\.taobao\.com\/.*$/,
      /^http(s)?:\/\/zc-item\.taobao\.com\/.*$/,
      /^http(s)?:\/\/item-paimai\.taobao\.com\/.*$/,
    ],
  },
  {
    id: 2,
    platformType: 'JD',
    name: '京东司法拍卖',
    patterns: [/^http(s)?:\/\/paimai\.jd\.com\/.*$/],
  },
  {
    id: 3,
    platformType: 'GPAI',
    name: '公拍网',
    patterns: [/^http(s)?:\/\/www\.gpai\.net\/.*$/],
  },
  {
    id: 4,
    platformType: 'RMFYSSZC',
    name: '人民法院诉讼资产网',
    patterns: [/^http(s)?:\/\/www\.rmfysszc\.gov\.cn\/.*$/],
  },
  {
    id: 5,
    platformType: 'CAA123',
    name: '中国拍卖行业协会',
    patterns: [/^http(s)?:\/\/sf\.caa123\.org\.cn\/.*$/],
  },
  {
    id: 6,
    platformType: 'CBEX',
    name: '北京产权所',
    patterns: [/^http(s)?:\/\/otc\.cbex\.com\/.*$/],
  },
  {
    id: 7,
    platformType: 'ICBC',
    name: '工商银行',
    patterns: [/^http(s)?:\/\/gf\.trade\.icbc\.com\.cn\/.*$/],
  },
]

export type AuctionPlatformType =
  | 'TAOBAO'
  | 'JD'
  | 'GPAI'
  | 'RMFYSSZC'
  | 'CAA123'
  | 'CBEX'
  | 'ICBC'

// 集合
export const auctionLink =
  /^http(s)?:\/\/(sf-item\.taobao\.com|susong-item\.taobao\.com|zc-item\.taobao\.com|item-paimai\.taobao\.com|paimai\.jd\.com|www\.gpai\.net|www\.rmfysszc\.gov\.cn|sf\.caa123\.org\.cn|otc\.cbex\.com|gf\.trade\.icbc\.com\.cn)\/.*$/

const negIntNumber = /(-?(\d+)?)/
const intNumber = /(\d+?)/
const floatNumberOne = /(\d+(\.\d)?)/
const floatNumberTwo = /(\d+(\.\d{1,2})?)/

function getTwoDecimalPlaces(thousandSeparatorString: string) {
  // const noThousandSeparator = thousandSeparatorString.replace(/,/g, '')
  // const numberRegexWithComma = /-?[0-9]{1,3}(,[0-9]{3})*\.?[0-9]*/g
  // 找到字符串中所有匹配的数字
  const numberRegexWithComma = /-?[0-9]+(\.[0-9]+)?(,[0-9]{3})*(\.[0-9]+)?/g
  const matches = thousandSeparatorString.match(numberRegexWithComma)

  if (matches) {
    const validNumbers = []

    // 遍历匹配到的数字
    for (let i = 0; i < matches.length; i++) {
      // const processedNumber = matches[i].replace(/,/g, '')
      const regexToRemoveAllExceptDot = /[^0-9.]/g
      const numberOnlyWithDot = matches[i].replace(
        regexToRemoveAllExceptDot,
        '',
      )
      let floatNumber
      if (
        numberOnlyWithDot.includes('.') &&
        numberOnlyWithDot.split('.').length === 2
      ) {
        floatNumber = parseFloat(numberOnlyWithDot)
      } else {
        floatNumber = parseInt(numberOnlyWithDot, 10)
      }
      // 只要最后拼接的字符串所表示的数值大于0就行
      if (floatNumber > 0 || validNumbers.length) {
        validNumbers.push(i == 0 ? floatNumber : numberOnlyWithDot)
      }
      // 判断是否大于等于0且只包含数字和小数点（通过再次检查转换后的浮点数是否能无损转换回原格式）
      // if (floatNumber >= 0 && numberOnlyWithDot === floatNumber.toString()) {
      //   validNumbers.push(numberOnlyWithDot)
      // }
    }
    console.log(validNumbers, '===validNumbers==')
    return validNumbers.join('')
  }
  return []
  // return noThousandSeparator
}

// 数字输入限制 整数或两位小数
export function numberLimit(num: any, precision = 2) {
  let res: any
  switch (precision) {
    case -1:
      res = negIntNumber.exec(num)
      break
    case 0:
      res = intNumber.exec(num)
      break
    case 1:
      res = floatNumberOne.exec(num)
      break
    default:
      res = floatNumberTwo.exec(getTwoDecimalPlaces(num))
      break
  }
  if (res instanceof Array && res.length > 0) {
    return res[0]
  } else {
    return ''
  }
}

// 区域code判断
export function isRightRegionId(rule: any, code: string, callback: any) {
  if (/^.{4}00$/.test(code)) {
    callback(new Error('请选择区县'))
  } else {
    callback()
  }
}

export function zeroCheck(rule: any, value: any, callback: any) {
  const re = /^\d+.?\d*$/
  if (!value) {
    callback(new Error('必填'))
  }
  setTimeout(() => {
    if (value && !re.test(value)) {
      callback(new Error('请输入大于0的数字值'))
    } else if (value && !(value.trim() > 0)) {
      callback(new Error('任务耗时不能为0'))
    } else {
      callback()
    }
  }, 500)
}

export const numberOrFloatTwo = /^(\d+(\.\d{1,2})?)$/
