import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { getData, postData } from '../request'

// 查询提成列表
export async function getCommissionListForTarget(
  params: CommissionListParams,
  pages: CommonPages,
) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  const res = await postData<CommonResult>(ApiPath.commissionListForTarget, {
    ...params,
    regionId: getLastItem(params.regionIds),
    regionIds: undefined,
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as CommissionTargetItemData[]
  return { list, total: res.totalCount }
}

export interface CommissionListParams {
  regionIds?: string[] // 法院所在地
  courtName?: string // 法院名称
  caseNum?: string // 案件号
  targetName?: string // 标的物名称
  targetNo?: string // 标的物编号
  distributionStatus?: number // 状态
  archiveStartTime?: Date | null
  archiveEndTime?: Date | null
}

export interface CommissionTargetItemData {
  distributionNo: number | null // 提成申请
  courtId: number
  targetId: number
  invoiceId: number
  entryId: number
  courtName: string
  caseNum: string
  targetNo: string
  targetName: string
  entryTime: string // 到账时间
  amount: string // 到账金额
  distributionAmount: string // 基础提成
  distributionStatus: number // 状态
  distributionStatusDesc: string // 节点状态
  canDistribution: 0 | 1
  auditOperation: 0 | 1 | 2 | 3
}

// 编辑分配明细
export function addDistributionData(params: AddDistributionParams) {
  return postData<DistributionData>(ApiPath.addDistributionData, params)
}

interface AddDistributionParams {
  entryId: number
  manageUserList: DistributionTeamUserItem[]
  teamUserList: DistributionTeamUserItem[]
  crossUserList: DistributionCrossUserItem[]
}

// 查看上次分配记录
export function getLastDistributionData(entryId: string | number) {
  return postData<DistributionData>(ApiPath.distributionDetailForCommission, {
    entryId,
    canDistribution: false,
  })
}

// 重新计算分配数据
export function getNewDistributionData(entryId: string | number) {
  return postData<DistributionData>(ApiPath.distributionDetailForCommission, {
    entryId,
    canDistribution: true,
  })
}

export interface DistributionData {
  entryId: number
  distributionStatus: DistributionStatus
  manageAmount: number
  teamAmount: number
  crossAmount: number
  manageUserList: DistributionTeamUserItem[]
  teamUserList: DistributionTeamUserItem[]
  crossUserList: DistributionCrossUserItem[]
}

export const ODistributionStatus = {
  WAIT_DISTRIBUTE: 0, // 待分配
  WAIT_CONFIRM: 1, // 待确认
  WAIT_FIRST_TRIAL: 2, // 待初审
  WAIT_SECOND_TRIAL: 3, // 待复审
  WAIT_ARCHIVE: 4, // 待归档
  ARCHIVED: 5, // 已归档
  GIVEN: 6, // 已发放
  FIRST_TRIAL_BACK: 7, // 初审驳回
  SECOND_TRIAL_BACK: 8, // 复审驳回
  WITHDRAW: 9, // 已撤回
  CLOSED: 10, // 已关闭
  HRBP_BACK: 11, // HRBP驳回
} as const

export type DistributionStatus =
  typeof ODistributionStatus[keyof typeof ODistributionStatus]

export type AuditOperation =
  | 0 // 可撤回
  | 1 // 可初审
  | 2 // 可复审
  | 3 // 可归档

interface DistributionTeamUserItem {
  userName: string
  userRoleCodeStr: string
  proportion: number | null
  amount: number | null
}

interface DistributionCrossUserItem {
  userName: string
  proportion: number | null
  amount: number | null
}

export function withdrawDistribution(params: {
  entryId: number
  remark: string
}) {
  return postData(ApiPath.withdrawDistribution, params)
}

export function closeDistribution(params: { entryId: number; remark: string }) {
  return postData(ApiPath.closeDistribution, params)
}

// 司辅提成管理权限
export async function getShowCityReward() {
  return await postData<boolean>(ApiPath.getShowCityReward)
}

// 司辅提成管理
export async function getApplyDistributionList(
  params: ApplyDistributionListParams,
  pages: CommonPages,
) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  const res = await postData<CommonResult>(ApiPath.getApplyDistributionList, {
    ...params,
    regionId: getLastItem(params.regionIds),
    regionIds: undefined,
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as ApplyDistributionItemData[]
  return { list, total: res.totalCount }
}
// 城市备用金列表
export async function getReserveList(
  params: ApplyReserveParams,
  pages: CommonPages,
) {
  const datas = {
    ...params,
    pageFlag: false,
    regionId: params.regionId,
    pageNum: pages.currentPage || 1,
    pageRow: pages.pageSize,
  }
  const res = await postData<CommonResult>(ApiPath.getReserveList, datas)
  const list = params.regionId
    ? (res.list as unknown[] as ApplyDistributionItemData[])
    : []
  const resSum = params.regionId
    ? await postData(ApiPath.getReserveSum, datas)
    : null
  return { list, total: res.totalCount, resSum }
}
export interface ApplyDistributionListParams {
  regionIds?: string[] // 法院所在地
  courtName?: string // 法院名称
  caseNum?: string // 案件号
  targetName?: string // 标的物名称
  targetNo?: string // 标的物编号
  distributionStatus?: DistributionStatus // 状态
  archiveStartTime?: Date | null
  archiveEndTime?: Date | null
}

export interface ApplyReserveParams {
  cityManagerName?: string //城市经理
  regionIds?: string[] // 备用金城市
  regionId?: string //备用金城市
}

export interface ApplyDistributionItemData {
  distributionNo: number | null // 提成申请
  courtId: number
  targetId: number
  invoiceId: number
  entryId: number
  courtName: string
  caseNum: string
  targetNo: string
  targetName: string
  // 服务收入
  realAmount: number // 可提成金额
  distributionAmount: number // 税后可提
  manageAmount: number // 管理提成
  teamAmount: number // 团队分配提成
  crossAmount: number // 异地协助
  // 城市奖金
  distributionStatus: DistributionStatus // 状态
  distributionStatusDesc: string // 节点状态
  auditOperation: 0 | 1 | 2 | 3
}

export async function getCommissionDetail(entryId: number) {
  const res = await getData<CommissionDetail>(
    ApiPath.getDistributionDetail(entryId),
  )
  return {
    ...res,
    users: res.users || [],
  }
}

export interface CommissionDetail {
  courtName: string
  caseNum: string
  targetName: string
  targetNo: string
  distributionNo: string
  entryTime: string
  serviceFee: number
  distributionAmount: number
  distributionAllRate: number
  realAmount: number
  manageRate: number
  caseRate: number
  crossFlag: boolean
  crossRate: number
  cityRewardRate: number
  cityRewardAmount: number
  users: CommissionDetailUser[]
}

export interface CommissionDetailUser {
  userId: number
  userName: string
  managerProportion: number | null
  managerAmount: number | null
  caseProportion: number | null
  caseAmount: number | null
  amount: number
  userRoleCodeStr: string
}

export function convertMyDistributionValue(params: MyDistributionListParams) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  return {
    ...params,
    regionIds: getLastItem(params.regionIds),
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
  }
}

export function newConvertMyDistributionValue(
  params: MyDistributionListParams,
) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  return {
    ...params,
    regionIds: newGetLastItem(params.regionIds),
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
  }
}

// 个人提成管理
export async function getMyDistributionList(
  params: MyDistributionListParams,
  pages: CommonPages,
) {
  const res = await postData<CommonResult>(ApiPath.selMyDistributionList, {
    ...newConvertMyDistributionValue(params),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as MyDistributionItemData[]
  return {
    list,
    total: res.totalCount,
    personalAmountSum: res.personalAmountSum as number,
  }
}

export interface MyDistributionListParams {
  regionIds?: string[] // 法院所在地
  courtName?: string // 法院名称
  caseNum?: string // 案件号
  targetName?: string // 标的物名称
  targetNo?: string // 标的物编号
  confirmStatus?: 0 | 1 | null // 是否确认
  distributionStatus?: DistributionStatus // 状态
  archiveStartTime?: Date | null
  archiveEndTime?: Date | null
  userName?: string | null // 员工姓名
  ruleDistributionNo?: string //提成编号
}

export interface MyDistributionItemData {
  distributionTypeDesc: string // 提成类型
  distributionNo: number | null // 提成申请
  courtId: number
  targetId: number
  invoiceId: number
  entryId: number
  entryDistributionId: number
  courtName: string
  caseNum: string
  targetNo: string
  targetName: string
  manageAmount: number // 管理提成
  realAmount: number // 提成金额
  confirmStatus: 0 | 1 | null // 是否确认
  distributionStatus: DistributionStatus // 状态
  distributionStatusDesc: string // 节点状态
}

export function confirmMyDistribution(params: {
  entryId: number
  entryDistributionId: number
}) {
  return postData(ApiPath.confirmMyDistribution, params)
}

// 查询操作日志
export function getCommissionLog(entryId: number) {
  return getData<CommissionLog[]>(ApiPath.getDistributionLog(entryId))
}

export interface CommissionLog {
  id: number
  operateTime: string
  operateType: number
  operateTypeDesc: string
  operatePersonName: null
  roleName: string
  consumeTime: number | null // 消耗时间
  remark: string | null
  nextType: string
  nextOperatePerson: string
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  [index: string]: unknown
  list: Record<string, unknown>[]
  totalCount: number
}

function newGetLastItem<T>(arr?: T[] | null) {
  return Array.isArray(arr) && arr.length !== 0
    ? arr.map((item: any) => Number(item[1]))
    : undefined
}

export function getLastItem<T>(arr?: T[] | null) {
  return Array.isArray(arr) && arr.length !== 0
    ? arr[arr.length - 1]
    : undefined
}
