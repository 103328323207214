import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { AuthorizedPass } from '@/components/authorized/permissions'

import { composeGuard, withPrefix } from './utils'
import persist from './guard/persist'
import auth from './guard/auth'
import secret from './guard/secret'
import authorize from './guard/authorize'

import DefaultLayout from '@/views/layouts/DefaultLayout.vue'
import SinglePage from '@/views/layouts/SinglePage.vue'

import NoAuth from '@/views/NoAuth.vue'
import PageException from '@/views/PageException.vue'
import PagePending from '@/views/PagePending.vue'
import SignedIn from '@/views/SignedIn.vue'
import SystemHelp from '@/views/SystemHelp.vue'
import PageForbidden from '@/views/PageForbidden.vue'

// 拍辅工作台
import Workbench from '@/views/workbench/workbench/WorkBench.vue'
import TargetServeList from '@/views/workbench/target-serve-list/TargetServeList.vue'
import DealSituationList from '@/views/workbench/deal-situation-list/DealSituationList.vue'
import OperateList from '@/views/workbench/operate-list/OperateList.vue'
import KpiManage from '@/views/workbench/kpi-manage/KpiManage.vue'

// 通知管理
import MessageCenter from '@/views/notices/messages/MessageCenter.vue'
import AlarmList from '@/views/notices/alarm-list/AlarmList.vue'
import TargetMainView from '@/views/target-detail/TargetMainView.vue'

// 团队管理
import MyTeamList from '@/views/team/MyTeamList.vue'
import DepartmentTeamList from '@/views/team/department-team/DepartmentTeamList.vue'

// 案件管理
import {
  CaseList,
  CaseDetail,
  DepartmentCaseList,
  MyTargetList,
  DepartmentTargetList,
  AuctionList,
  myAuctionDetail,
} from '@/views/case'
import TargetList from '@/views/target/TargetList.vue'

// 任务管理
import TaskList from '@/views/task/TaskList.vue'
import TasksList from '@/views/task/TasksList.vue'
import DepartmentTasksList from '@/views/task/DepartmentTasksList.vue'
import SetTaskStatus from '@/views/task/SetTaskStatus.vue'
import DepartmentTaskStatus from '@/views/task/DepartmentTaskStatus.vue'

// 看样管理
import MySampleList from '@/views/sample/my-sample/MySampleList.vue'
import Registration from '@/views/sample/registration/Registration.vue'
import DepartmentSample from '@/views/sample/department-my-sample/DepartmentSample.vue'
import DepartmentRegistration from '@/views/sample/department-registration/DepartmentRegistration.vue'

// 咨询管理
import ConsultList from '@/views/consult/ConsultList.vue'

// 结算管理
import InvoiceExam from '@/views/apply/invoice-exam/InvoiceExam.vue'
import InvoiceApply from '@/views/apply/invoice-apply/InvoiceApply.vue'
import DepartmentApply from '@/views/apply/department-apply/DepartmentApply.vue'

// 规则管理
import ChargeRuleList from '@/views/charge/charge-rule-list/ChargeRuleList.vue'
import CreateChargeRule from '@/views/charge/CreateChargeRule.vue'
import ChargeCompanies from '@/views/charge/companies/ChargeCompanies.vue'
import ChargeCommission from '@/views/charge/commission/ChargeCommission.vue'

// 系统管理
import RelationshipList from '@/views/system/relationship-create/RelationshipList.vue'

// 提成管理
import CommissionManage from '@/views/performance/CommissionManage.vue'
import MyCommission from '@/views/performance/MyCommission.vue'

// 提成管理(新规则)
import CommissionListWait from '@/views/paid-commission/commission-list-wait/CommissionListWait.vue'
import DistributionListApply from '@/views/paid-commission/distribution-list-apply/DistributionListApply.vue'
import CityRewardList from '@/views/paid-commission/city-reward-list/CityRewardList.vue'
import CityRettyCash from '@/views/paid-commission/city-petty-cash/CityRettyCash.vue'
import MyPaidCommission from '@/views/paid-commission/my-distribution-list/PaidCommission.vue'

// 审批管理(新规则)
import DistributionListAudit from '@/views/paid-commission/distribution-list-audit/DistributionList.vue'
import CityRewardListAudit from '@/views/paid-commission/city-reward-list-audit/CityRewardListAudit.vue'

// 公告检验
import AnnouncementTestResultList from '@/views/announcement-test/AnnouncementTestResultList.vue'
const AnnouncementTestResultDataV = () =>
  import('@/views/announcement-test/AnnouncementTestResultDataV.vue')

// 案款分配
import FundsDistributionList from '@/views/funds-distribution/FundsDistributionList.vue'
import AddDistribution from '@/views/funds-distribution/AddDistribution.vue'

// 帮助中心
import HelpCenter from '@/views/system/help-center/HelpCenter.vue'

// 报表管理
import {
  TaskSummary,
  CaseStatements,
  CrossAreaTarget,
  ServiceCorporeSummary,
  ServiceTaskSummary,
  ServiceTaskUser,
  OperationWeekly,
  ProvincialDevelopService,
  PointCourtServiceTarget,
  InvoiceInformation,
  CourtOperationAnalysis,
  OverallOperation,
} from '@/views/report-form'

Vue.use(VueRouter)

export const routes: RouteConfig[] = [
  {
    path: '/no-auth',
    name: 'no-auth',
    component: NoAuth,
    meta: {
      guard: persist,
    },
  },
  {
    path: '/403',
    name: '403',
    component: PageException,
    meta: {
      guard: persist,
    },
  },
  {
    path: '/pending',
    component: DefaultLayout,
    meta: {
      guard: persist,
    },
    children: [{ path: '', component: PagePending }],
  },
  {
    path: '/signed_in',
    component: DefaultLayout,
    meta: {
      guard: persist,
    },
    children: [{ path: '', name: 'signed_in', component: SignedIn }],
  },
  {
    path: '/help',
    component: DefaultLayout,
    meta: {
      guard: persist,
    },
    children: [{ path: '', component: SystemHelp }],
  },
  {
    path: '/single',
    component: SinglePage,
    meta: {
      guard: [persist, auth, secret, authorize],
    },
    children: [
      {
        path: 'testResultList',
        component: AnnouncementTestResultList,
        meta: {
          permission: 'testResultList',
        },
        children: [
          {
            path: 'testResultDataV',
            component: AnnouncementTestResultDataV,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: DefaultLayout,
    meta: {
      guard: [persist, auth, secret, authorize],
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: PageForbidden,
      },
      // ******************** 帮助中心 ********************
      {
        path: 'help-center',
        name: 'help-center',
        component: HelpCenter,
      },
      // ******************** 拍辅工作台 ********************
      ...withPrefix('workbench', [
        {
          path: 'home',
          name: 'home',
          component: Workbench,
        },
        {
          path: 'target-serve-list',
          name: 'target-serve-list',
          component: TargetServeList,
          props: route => ({
            queryType: route.query.queryType,
            province: route.query.province,
            city: route.query.city,
            initParams: route.query.initParams,
          }),
        },
        {
          path: 'deal-situation-list',
          name: 'deal-situation-list',
          component: DealSituationList,
          props: route => ({
            queryType: route.query.queryType,
            province: route.query.province,
            city: route.query.city,
            initParams: route.query.initParams,
          }),
        },
        {
          path: 'operate-list',
          name: 'operate-list',
          component: OperateList,
          props: route => ({
            queryType: route.query.queryType,
            province: route.query.province,
            city: route.query.city,
            initParams: route.query.initParams,
          }),
        },
        {
          path: 'kpi-manage',
          name: 'kpi-manage',
          component: KpiManage,
        },
      ]),
      // ******************** 通知管理 ********************
      ...withPrefix('notices', [
        {
          path: 'message',
          name: 'message',
          component: MessageCenter,
        },
        {
          path: 'alarm-list',
          name: 'alarm-list',
          component: AlarmList,
          children: [
            {
              path: 'target-detail/:targetId',
              props: route => ({
                targetId: route.params.targetId,
                source: '/notices/alarm-list',
              }),
              component: TargetMainView,
            },
          ],
        },
      ]),
      // ******************** 团队管理 ********************
      {
        path: 'myTeam',
        name: 'myTeam',
        component: MyTeamList,
      },
      {
        path: 'departmentTeam',
        name: 'departmentTeam',
        component: DepartmentTeamList,
      },
      // ******************** 案件管理 ********************
      {
        path: 'caseList',
        name: 'caseList',
        component: CaseList,
        children: [
          {
            path: 'detail/:id',
            name: 'caseDetail',
            component: CaseDetail,
          },
          {
            path: 'targetList/:id',
            name: 'targetList',
            component: TargetList,
          },
          {
            path: 'targetDetail/:id',
            name: 'targetDetail',
            props: route => {
              const [id, simple] = ('' + route.params.id).split('-')
              return {
                targetId: id,
                category: simple === 'simple' ? 'simple' : 'case',
                source: '/caseList',
              }
            },
            component: TargetMainView,
          },
        ],
      },
      {
        path: 'auctionList',
        name: 'auctionList',
        component: AuctionList,
        children: [
          {
            path: 'detail/:id',
            name: 'aucitonDetail',
            props: route => ({
              targetId: route.params.id,
            }),
            component: myAuctionDetail,
          },
        ],
      },
      {
        path: 'departmentCaseList',
        name: 'departmentCaseList',
        component: DepartmentCaseList,
        children: [
          {
            path: 'detail/:id',
            name: 'departmentCaseDetail',
            component: CaseDetail,
          },
          {
            path: 'targetList/:id',
            name: 'departmentCaseTargetList',
            component: TargetList,
          },
          {
            path: 'targetDetail/:id',
            name: 'departmentCaseTargetDetail',
            props: route => {
              const [id, simple] = ('' + route.params.id).split('-')
              return {
                targetId: id,
                category: simple === 'simple' ? 'simple' : 'case',
                source: '/departmentCaseList',
              }
            },
            component: TargetMainView,
          },
        ],
      },
      {
        path: 'myTargetList',
        name: 'myTargetList',
        component: MyTargetList,
        children: [
          {
            path: 'targetDetail/:id',
            name: 'myTargetDetail',
            props: route => ({
              targetId: route.params.id,
              category: route.params.pageType || null,
              source: '/myTargetList',
            }),
            component: TargetMainView,
          },
        ],
      },
      {
        path: 'departmentTargetList',
        name: 'departmentTargetList',
        component: DepartmentTargetList,
        children: [
          {
            path: 'targetDetail/:id',
            name: 'departmentTargetDetail',
            props: route => ({
              targetId: route.params.id,
              source: '/departmentTargetList',
            }),
            component: TargetMainView,
          },
        ],
      },
      {
        path: 'departmentTargetList',
        name: 'departmentTargetList',
        component: DepartmentTargetList,
        children: [
          {
            path: 'targetDetail/:id',
            name: 'departmentTargetDetail',
            props: route => ({
              targetId: route.params.id,
              source: '/departmentTargetList',
            }),
            component: TargetMainView,
          },
        ],
      },
      // ******************** 任务管理 ********************
      {
        path: 'taskList',
        name: 'taskList',
        component: TaskList,
      },
      {
        path: 'tasksList',
        name: 'tasksList',
        component: TasksList,
      },
      {
        path: 'departmentTasksList',
        name: 'departmentTasksList',
        component: DepartmentTasksList,
      },
      {
        path: 'setTaskStatus',
        name: 'setTaskStatus',
        component: SetTaskStatus,
      },
      {
        path: 'departmentTaskStatus',
        name: 'departmentTaskStatus',
        component: DepartmentTaskStatus,
      },
      // ******************** 看样管理 ********************
      ...withPrefix('sample', [
        {
          path: 'my_sample_list',
          name: 'my_sample_list',
          component: MySampleList,
          children: [
            {
              path: 'targetDetail/:id',
              props: route => ({
                targetId: route.params.id,
                source: '/sample/my_sample_list',
              }),
              component: TargetMainView,
            },
          ],
        } as RouteConfig,
        {
          path: 'registration',
          name: 'registration',
          component: Registration,
        },
        {
          path: 'department_sample_list',
          name: 'department_sample_list',
          component: DepartmentSample,
          children: [
            {
              path: 'targetDetail/:id',
              props: route => ({
                targetId: route.params.id,
                source: '/sample/department_sample_list',
              }),
              component: TargetMainView,
            },
          ],
        },
        {
          path: 'department_registration',
          name: 'department_registration',
          component: DepartmentRegistration,
        },
      ]),
      // ******************** 咨询管理 ********************
      {
        path: 'consult-list',
        name: 'consult_list',
        component: ConsultList,
      },
      // ******************** 结算管理 ********************
      ...withPrefix('apply', [
        {
          path: 'invoice-exam-list',
          name: 'invoice-exam-list',
          component: InvoiceExam,
        },
        {
          path: 'invoice-apply-list',
          name: 'invoice-apply-list',
          component: InvoiceApply,
        },
        {
          path: 'department-apply-list',
          name: 'department-apply-list',
          component: DepartmentApply,
        },
      ]),
      // ******************** 规则管理 ********************
      {
        path: 'chargeRuleList',
        name: 'chargeRuleList',
        component: ChargeRuleList,
      },
      {
        path: 'chargeRuleList/createChargeRule/:id/:bind',
        name: 'createChargeRule',
        component: CreateChargeRule,
      },
      {
        path: 'ChargeCompanies',
        name: 'ChargeCompanies',
        component: ChargeCompanies,
      },
      {
        path: 'ChargeCommission',
        name: 'ChargeCommission',
        component: ChargeCommission,
      },
      // ******************** 系统管理 ********************
      {
        path: 'relationshipList',
        name: 'relationshipList',
        component: RelationshipList,
      },
      // ******************** 提成管理 ********************
      ...withPrefix('performance-manage', [
        {
          path: 'commission-manage',
          name: 'commission-manage',
          component: CommissionManage,
        },
        {
          path: 'my-commission',
          name: 'my-commission',
          component: MyCommission,
        },
      ]),
      // ******************** 提成管理(新规则) ********************
      ...withPrefix('paid-commission', [
        {
          path: 'commission-list-wait',
          name: 'commission-list-wait',
          component: CommissionListWait,
        },
        {
          path: 'distribution-list-apply',
          name: 'distribution-list-apply',
          component: DistributionListApply,
        },
        {
          path: 'city-reward-list',
          name: 'city-reward-list',
          component: CityRewardList,
        },
        {
          path: 'city-petty-cash',
          name: 'city-petty-cash',
          component: CityRettyCash,
        },
        {
          path: 'my-distribution-list',
          name: 'my-distribution-list',
          component: MyPaidCommission,
        },
      ] as RouteConfig[]),
      // ******************** 审批管理(新规则) ********************
      ...withPrefix('audit-commission', [
        {
          path: 'distribution-list',
          name: 'distribution-list-audit',
          component: DistributionListAudit,
        },
        {
          path: 'city-reward-list',
          name: 'city-reward-list-audit',
          component: CityRewardListAudit,
        },
      ] as RouteConfig[]),
      // ******************** 公告检验 ********************
      {
        path: 'testResultList',
        name: 'testResultList',
        component: AnnouncementTestResultList,
        children: [
          {
            path: 'testResultDataV',
            name: 'testResultDataV',
            component: AnnouncementTestResultDataV,
          },
        ],
      },
      // ******************** 案款分配 ********************
      {
        path: 'fundsDistributionList',
        name: 'fundsDistributionList',
        component: FundsDistributionList,
        children: [
          {
            path: 'addDistribution/:caseId',
            name: 'addDistribution',
            component: AddDistribution,
          },
        ],
      },
      // ******************** 报表管理 ********************
      ...withPrefix('report-form', [
        {
          path: 'task-summary',
          name: 'task-summary',
          component: TaskSummary,
        },
        {
          path: 'case-statements',
          name: 'case-statements',
          component: CaseStatements,
        },
        {
          path: 'cross-area-target',
          name: 'cross-area-target',
          component: CrossAreaTarget,
        },
        {
          path: 'service-corpore-summary',
          name: 'service-corpore-summary',
          component: ServiceCorporeSummary,
        },
        {
          path: 'service-task-summary',
          name: 'service-task-summary',
          component: ServiceTaskSummary,
          children: [
            {
              path: 'user/:id',
              name: 'service-task-summary-user',
              component: ServiceTaskUser,
            },
          ],
        },
        {
          path: 'operation-weekly',
          name: 'operation-weekly',
          component: OperationWeekly,
        },
        {
          path: 'provincial-develop-service',
          name: 'provincial-develop-service',
          component: ProvincialDevelopService,
        },
        {
          path: 'point-court-service-target',
          name: 'point-court-service-target',
          component: PointCourtServiceTarget,
        },
        {
          path: 'invoice-information',
          name: 'invoice-information',
          component: InvoiceInformation,
        },
        {
          path: 'court-operation-analysis',
          name: 'court-operation-analysis',
          component: CourtOperationAnalysis,
        },
        {
          path: 'overall-operation',
          name: 'overall-operation',
          component: OverallOperation,
        },
      ]),
      {
        path: '*',
        redirect: '/pending',
      },
    ] as RouteConfig[],
  },
]

const router = new VueRouter({ routes })

function subtract<T>(left: T[], right: T[], equal: (a: T, b: T) => unknown) {
  const index = left.findIndex(
    (record, index) => index > right.length - 1 || !equal(record, right[index]),
  )
  return index === -1 ? [] : left.slice(index)
}

router.beforeEach((to, from, next) => {
  const list = subtract(to.matched, from.matched, (a, b) => a.path === b.path)
  const guards = list.map(record => record.meta.guard).flat()
  const composed = composeGuard(...guards)
  if (composed) {
    composed(to, from, next)
  } else {
    next()
  }
})

export default router

export const asideMenus: AsideMenuItem[] = [
  {
    name: 'workbench',
    title: '拍辅工作台',
    icon: 'el-icon-data-line',
    children: [
      {
        name: 'home',
        title: '工作台',
      },
      {
        name: 'target-serve-list',
        title: '标的服务报表',
      },
      {
        name: 'deal-situation-list',
        title: '成交情况报表',
      },
      {
        name: 'operate-list',
        title: '经营报表',
      },
      {
        name: 'kpi-manage',
        title: '目标设置',
      },
    ],
  },
  {
    name: 'notices',
    title: '通知管理',
    icon: 'el-icon-message',
    children: [
      {
        name: 'message',
        title: '消息中心',
      },
      {
        name: 'alarm-list',
        title: '预警中心',
      },
    ],
  },
  {
    name: 'team',
    title: '团队管理',
    icon: 'el-icon-menu',
    children: [
      {
        name: 'myTeam',
        title: '我的团队',
      },
      {
        name: 'departmentTeam',
        title: '法院设置',
      },
    ],
  },
  {
    name: 'case',
    title: '案件管理',
    icon: 'el-icon-document',
    children: [
      {
        name: 'caseList',
        title: '我的案件',
      },
      {
        name: 'departmentCaseList',
        title: '部门案件',
      },
      {
        name: 'myTargetList',
        title: '我的标的',
      },
      {
        name: 'departmentTargetList',
        title: '部门标的',
      },
      {
        name: 'auctionList',
        title: '拍卖记录',
      },
    ],
  },
  {
    name: 'task',
    title: '任务管理',
    icon: 'el-icon-tickets',
    children: [
      {
        name: 'taskList',
        title: '我的任务',
      },
      {
        name: 'tasksList',
        title: '任务列表',
      },
      {
        name: 'departmentTasksList',
        title: '部门任务',
      },
      {
        name: 'setTaskStatus',
        title: '设置任务类型',
      },
      {
        name: 'departmentTaskStatus',
        title: '任务类型查询',
      },
    ],
  },
  {
    name: 'consult_sample',
    title: '客户咨询&看样',
    icon: 'el-icon-news',
    children: [
      {
        name: 'my_sample_list',
        title: '我的带看',
      },
      {
        name: 'registration',
        title: '预约登记',
      },
      {
        name: 'department_sample_list',
        title: '部门带看',
      },
      {
        name: 'department_registration',
        title: '部门预约登记',
      },
      {
        name: 'consult_list',
        title: '咨询列表',
      },
    ],
  },
  {
    name: 'apply',
    title: '结算管理',
    icon: 'el-icon-edit',
    children: [
      {
        name: 'invoice-exam-list',
        title: '发票审核',
      },
      {
        name: 'invoice-apply-list',
        title: '发票申请',
      },
      {
        name: 'department-apply-list',
        title: '部门申请记录',
      },
    ],
  },
  {
    name: 'charge',
    title: '规则管理',
    icon: 'el-icon-goods',
    children: [
      {
        name: 'chargeRuleList',
        title: '收费规则',
      },
      {
        name: 'ChargeCompanies',
        title: '签约公司',
      },
      {
        name: 'ChargeCommission',
        title: '提成规则',
      },
    ],
  },
  {
    name: 'system',
    title: '系统管理',
    icon: 'el-icon-setting',
    children: [
      {
        name: 'relationshipList',
        title: '关系创建',
      },
    ],
  },
  {
    name: 'performance-manage',
    title: '提成管理',
    icon: 'el-icon-star-off',
    children: [
      {
        name: 'commission-manage',
        title: '业务提成管理',
      },
      {
        name: 'my-commission',
        title: '我的业务提成',
      },
    ],
  },
  {
    name: 'paid-commission',
    title: '提成管理(新规则)',
    icon: 'el-icon-star-off',
    children: [
      {
        name: 'commission-list-wait',
        title: '待申请',
      },
      {
        name: 'distribution-list-apply',
        title: '司辅提成管理',
      },
      {
        name: 'city-reward-list',
        title: '城市奖金管理',
      },
      {
        name: 'city-petty-cash',
        title: '城市备用金',
      },
      {
        name: 'my-distribution-list',
        title: '个人提成管理',
      },
    ],
  },
  {
    name: 'audit-commission',
    title: '审批管理(新规则)',
    icon: 'el-icon-star-off',
    children: [
      {
        name: 'distribution-list-audit',
        title: '司辅提成审批',
      },
      {
        name: 'city-reward-list-audit',
        title: '城市奖金审批',
      },
    ],
  },
  {
    name: 'announcementTest',
    title: '公告检验',
    icon: 'el-icon-star-off',
    children: [
      {
        name: 'testResultList',
        title: '检验结果列表',
      },
    ],
  },
  {
    name: 'fundsDistribution',
    title: '案款分配管理',
    icon: 'el-icon-star-off',
    children: [
      {
        name: 'fundsDistributionList',
        title: '案款分配列表',
      },
    ],
  },
  {
    name: 'report-form',
    title: '报表管理',
    icon: 'el-icon-printer',
    children: [
      {
        name: 'task-summary',
        title: '任务汇总表',
      },
      {
        name: 'case-statements',
        title: '案件结算表',
      },
      {
        name: 'cross-area-target',
        title: '异地服务管理表',
      },
      {
        name: 'service-corpore-summary',
        title: '服务标的汇总表',
      },
      {
        name: 'service-task-summary',
        title: '服务任务汇总表',
      },
      {
        name: 'operation-weekly',
        title: '法院运营周报',
      },
      {
        name: 'court-operation-analysis',
        title: '法院运营情况分析表',
      },
      {
        name: 'overall-operation',
        title: '整体运营情况分析表',
      },
      {
        name: 'provincial-develop-service',
        title: '省区拓展服务表',
      },
      {
        name: 'point-court-service-target',
        title: '驻点法院服务标的表',
      },
      {
        name: 'invoice-information',
        title: '开票信息表',
      },
    ],
  },
]

// 深度遍历查找第一个有效菜单
export function selectRoute() {
  interface MenuItem {
    name: string
    children?: MenuItem[]
  }
  let list: MenuItem[] = asideMenus.slice()
  let tail = list.shift()
  while (tail) {
    if (tail.children && tail.children.length) {
      list = tail.children.concat(list)
    } else if (AuthorizedPass(tail.name)) {
      return tail
    }
    tail = list.shift()
  }
}

interface AsideMenuItem {
  name: string
  title: string
  icon: string
  children?: SubMenuItem[]
}

interface SubMenuItem {
  name: string
  title: string
}
