import {
  computed,
  ref,
  Ref,
  shallowReactive,
  shallowRef,
  watch,
} from '@vue/composition-api'
import { Message } from 'element-ui'
import { usePromise } from 'vue-promised'

export function useCommonList<T extends object, R extends object>(
  initParams: T,
  getCommonList: GetListFunc<T, R>,
) {
  const getList = getListWithPage(getCommonList)

  const params = shallowReactive({ ...initParams })
  const savedParams: Ref<T> = shallowRef({ ...initParams })

  const { innerPages, currentPage, pageSize } = usePagination(setPromise)
  const dataPromise = ref(getList(savedParams.value, innerPages.value))
  function setPromise() {
    dataPromise.value = getList(savedParams.value, innerPages.value)
  }
  const promised = usePromise(dataPromise)
  const isElapsedPending = computed(
    () => promised.isPending.value && promised.isDelayElapsed.value,
  )

  watch(promised.error, err => {
    if (err) {
      Message.error(err.message)
    }
  })

  const { orderWidth, getIndex } = useOrderIndex(promised.data)

  function handleSubmit() {
    valueTrim(params)
    savedParams.value = { ...params }
    innerPages.value.currentPage = 1
    setPromise()
  }

  return {
    ...promised,
    isElapsedPending,
    params,
    savedParams,
    currentPage,
    pageSize,
    orderWidth,
    getIndex,
    handleSubmit,
    handleRefresh: setPromise,
  }
}

export function useCommonLazyList<T extends object, R extends object>(
  getCommonList: GetListFunc<T, R>,
) {
  const getList = getListWithPage(getCommonList)

  const savedParams: Ref<T | null> = shallowRef(null)

  const { innerPages, currentPage, pageSize } = usePagination(setPromise)

  const dataPromise = ref<ReturnType<typeof getList> | null>(null)
  function setPromise() {
    dataPromise.value =
      savedParams.value && getList(savedParams.value, innerPages.value)
  }
  const promised = usePromise(dataPromise)

  const isElapsedPending = computed(
    () => promised.isPending.value && promised.isDelayElapsed.value,
  )

  watch(promised.error, err => {
    if (err) {
      Message.error(err.message)
    }
  })

  const { orderWidth, getIndex } = useOrderIndex(promised.data)

  function handleSubmit(params: T) {
    valueTrim(params)
    savedParams.value = params
    innerPages.value.currentPage = 1
    setPromise()
  }

  return {
    ...promised,
    isElapsedPending,
    savedParams,
    currentPage,
    pageSize,
    orderWidth,
    getIndex,
    handleSubmit,
    handleRefresh: setPromise,
  }
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface GetListFunc<T, R> {
  (params: T, pages: CommonPages): Promise<R>
}

function usePagination(setCallback: () => void) {
  const innerPages = ref<CommonPages>({ currentPage: 1, pageSize: 10 })
  const currentPage = computed({
    get: () => innerPages.value.currentPage,
    set: newVal => {
      const oldVal = innerPages.value.currentPage
      innerPages.value.currentPage = newVal
      // 增加不为空的判断
      if (newVal && oldVal && newVal !== oldVal) {
        setCallback()
      }
    },
  })
  const pageSize = computed({
    get: () => innerPages.value.pageSize,
    set: newVal => {
      const oldVal = innerPages.value.pageSize
      innerPages.value.pageSize = newVal
      if (newVal !== oldVal) {
        setCallback()
      }
    },
  })

  return { innerPages, currentPage, pageSize }
}

function useOrderIndex(
  data: Ref<
    { pages: { currentPage: number; pageSize: number } } | null | undefined
  >,
) {
  const orderWidth = computed(() => {
    const res = data.value
    if (!res) {
      return 62
    }
    const { currentPage, pageSize } = res.pages
    const total = currentPage * pageSize
    return 20 + 14 * Math.max(3, total.toString().length / 2)
  })
  function getIndex(index: number) {
    const res = data.value
    if (!res) {
      return 0
    }
    const { currentPage, pageSize } = res.pages
    return (currentPage - 1) * pageSize + index + 1
  }

  return { orderWidth, getIndex }
}

function getListWithPage<T extends object, R extends object>(
  getCommonList: GetListFunc<T, R>,
) {
  return async (params: T, pages: CommonPages) => {
    const { currentPage, pageSize } = pages
    const curPages = { currentPage, pageSize }
    const res = await getCommonList(params, curPages)
    return { ...res, pages: curPages }
  }
}

function valueTrim<T extends object>(params: T) {
  ;(Object.keys(params) as (keyof T)[]).forEach(k => {
    const val = params[k]
    if (typeof val === 'string') {
      const tVal = val.trim() as typeof val
      if (val !== tVal) {
        params[k] = tVal
      }
    }
  })
}
