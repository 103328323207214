var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"edit-form__top-block"},[_c('el-form-item',{attrs:{"label":"添加分配人："}},[_c('div',{staticClass:"select-block__box"},[_c('MemberSelect',{attrs:{"region-ids":_vm.regionIds},on:{"change":_vm.handleMemberChange}}),_c('div',{staticClass:"select-block_left"},[_c('div',{staticClass:"button_List"},[_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.addMembers}},[_vm._v(" 一键添加成员 ")]),_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.calculateMean}},[_vm._v(" 计算均值 ")]),_c('el-button',{attrs:{"type":"danger","plain":""},on:{"click":_vm.addRettyCash}},[_vm._v(" +城市备用金 ")])],1),_c('div',{staticClass:"select-block__block"},[_c('div',{staticClass:"select-block__label"},[_vm._v("选择人员：")]),_c('div',{staticClass:"select-block__value"},[_vm._v(_vm._s(_vm.users.length))]),_c('div',{staticClass:"select-block__unit"},[_vm._v("人")]),_c('div',{staticClass:"select-block__separator"},[_vm._v("|")]),_c('div',{staticClass:"select-block__label"},[_vm._v("剩余金额：")]),_c('div',{staticClass:"select-block__value",style:({
                color: Number(_vm.totalRate) < 0 ? '#f56c6c' : '#303859',
              })},[_vm._v(" "+_vm._s(_vm.totalRate)+" ")])])])],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.addMembersLoading),expression:"addMembersLoading"}],attrs:{"stripe":"","border":"","data":_vm.users}},[_c('el-table-column',{attrs:{"type":"index","width":"50","align":"center"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"姓名","align":"center"}}),_c('el-table-column',{attrs:{"label":"分配金额（元）","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
              var $index = ref.$index;
return [_c('el-input',{attrs:{"value":row.amount},on:{"change":function($event){return _vm.handleUserChange($index)},"input":function($event){return _vm.handleFixInput(row, $event)}}})]}}])}),_c('el-table-column',{attrs:{"prop":"proportion","label":"分配比例（%）","align":"center"}}),_c('el-table-column',{attrs:{"width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var $index = ref.$index;
return [_c('div',{staticClass:"edit-form__icon",on:{"click":function($event){return _vm.handleDeleteUser($index)}}},[_c('i',{staticClass:"el-icon-delete"})])]}}])})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-form__head"},[_c('div',{staticClass:"edit-form__head-text"},[_vm._v("团队分配")])])}]

export { render, staticRenderFns }