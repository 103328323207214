export const API_URL = process.env.VUE_APP_API_URL
export const BS_API_URL = process.env.VUE_APP_BS_API_URL

export default {
  // sso 登出接口
  logout: `${API_URL}sso/logout`,

  // sso 获取用户信息
  getUserInfo: `${API_URL}sso/getLoginUserInfo`,

  // sso 获取用户权限信息
  getUserPermission: `${API_URL}sso/getLoginUserPermission`,

  // 获取预警通知总数
  getNotifyCount: `${API_URL}alarm/alarmCount`,

  // 获取预警列表
  getNotifyList: `${API_URL}alarm/alarmList`,

  // 预警列表导出
  exportNotifyList: `${API_URL}alarm/alarmListExport`,

  // 处理预警
  dealNotify: `${API_URL}alarm/dealAlarm`,

  // 阅读预警
  readNotify: `${API_URL}alarm/readAlarm`,

  // 查询标的下的预警数
  queryAlarmCountOfTarget: (targetId: string | number) =>
    `${API_URL}alarm/targetAlarmCount/${targetId} `,

  // 获取下级部门
  getSubDepartment: `${API_URL}depart/getDepartSelect`,

  // 获取部门下的法服BD
  getStaffOfDepartment: `${API_URL}depart/getUserByDepartmentId`,

  // 获取部门下的绑定的法院
  getCourtInDepartment: `${API_URL}depart/getCourtByDepartment`,

  // 获取指定行政区下一级别区域
  getDistrictList: `${API_URL}common/cityList`,

  // 查询地区法院
  getCourtInDistrict: `${API_URL}relation/selClient`,

  // 查询地区法院绑定关系
  getCourtBind: `${API_URL}relation/selCrmCourtByList`,

  // 查询crm服务法院
  getCrmCourt: `${API_URL}relation/selCrmCourt`,

  // 查询入驻法院列表
  getServiceCourt: `${API_URL}relation/selServiceCourt`,

  // 获取带看团队列表
  getSampleMemberList: `${API_URL}task/editView`,

  // 获取团队信息新版
  getSampleMemberListByAdmin: `${API_URL}task/editViewByAdmin`,

  // 带看团队搜索用户
  getMemberInfoList: `${API_URL}task/getViewUser`,

  // 用户角色信息
  getRoleByUser: `${API_URL}user/selRoleByUser`,

  // 带看团队搜索用户
  updateMemberInfoList: `${API_URL}task/addView`,

  // -------------------- 团队模块 : team Controller --------------------
  // 我的团队
  myTeamList: `${API_URL}relation/myTeamList`,
  // 部门团队
  departmentTeamList: `${API_URL}relation/selBDUserList`,

  // -------------------- 带看模块 : Look Controller --------------------
  // 我的带看列表
  getSampleList: `${API_URL}look/lookList`,

  // 预约登记列表
  getRegistrationList: `${API_URL}look/checkInLookList`,

  // 预约登记列表导出
  exportRegistrationList: `${API_URL}look/checkInLookListExport`,

  // 我的带看导出
  exportSampleList: `${API_URL}look/lookExport`,

  // 部门带看列表
  getDepartmentSampleList: `${API_URL}look/departmentLookList`,

  // 部门预约登记列表
  getDepartmentRegistrationList: `${API_URL}look/departmentCheckInLookList`,

  // 部门预约登记列表导出
  exportDepartmentRegistrationList: `${API_URL}look/departmentCheckInLookListExport`,

  // 部门带看导出
  exportDepartmentSampleList: `${API_URL}look/departmentLookExport`,

  // BD 跟踪信息
  getCustomerInfo: `${API_URL}look/customerInfo`,

  // 标的物列表
  getTargetList: `${API_URL}look/targetList`,

  // 标的物下看样任务列表
  getTaskListForTarget: (targetId: string | number) =>
    `${API_URL}look/getViewTargetTask/${targetId}`,

  // 看样客户补录
  putSampleInfo: `${API_URL}look/lookCustom`,

  // 看样预约登记新增
  addLookView: `${API_URL}look/addView`,

  // 看样客户补录
  getSampleListTemplate: `${API_URL}look/downloadTemp`,

  // 批量上传客户补录文件
  uploadSampleList: `${API_URL}look/importLookList`,

  // 根据标的物或者任务查询看样预约人数
  getTargetViewsNumByTarget: (targetId: string | number) =>
    `${API_URL}look/getTargetViews/${targetId}`,
  getTargetViewsNumByTask: (taskId: string | number) =>
    `${API_URL}look/getTaskViews/${taskId}`,
  getAuctionViews: (auctionId: string | number) =>
    `${API_URL}look/getAuctionViews/${auctionId}`,

  // 查询用户标签
  getCustomerTag: (customerId: string | number) =>
    `${API_URL}customer/getCustomerTag/${customerId}`,
  // 保存用户标签
  saveCustomerTag: `${API_URL}customer/saveCustomerTag`,
  // 根据手机号查询用户姓名
  getCustomerNameByPhone: (phone: string | number) =>
    `${API_URL}customer/getCustomerNameByPhone/${phone}`,

  // -------------------- 发票模块 : invoice Controller --------------------
  // 发票申请
  myInvoiceList: `${API_URL}invoice/myInvoiceList`,

  // 部门申请
  getInvoiceList: `${API_URL}invoice/getInvoiceList`,

  // 修改标的物状态
  updateTargetById: `${API_URL}case/updateTarget`,

  // 查询与标的物相关的发票
  getInvoiceOfTarget: `${API_URL}invoice/getInvoiceTitle`,

  // 查询可选发票列表
  getInvoiceTitleList: (targetId: string | number) =>
    `${API_URL}relation/court/titles/${targetId}`,

  // 修改发票信息
  postInvoiceInfo: `${API_URL}invoice/saveOrUpdateInvoice`,

  // 修改发票回款超期说明
  postInvoiceDelayReason: `${API_URL}invoice/saveDelayReason`,

  // 退回发票
  withdrawInvoice: `${API_URL}invoice/withdrawInvoice`,

  // 查询发票信息
  getInvoiceDetail: `${API_URL}invoice/getInvoiceDetail`,

  // 获取发票操作记录
  getInvoiceOperateLog: `${API_URL}invoice/getOperateLog`,

  // 导出发票信息
  exportInvoiceList: `${API_URL}invoice/getInvoiceListExport`,

  // 导入发票信息
  importInvoiceList: `${API_URL}invoice/importInvoiceList`,

  // -------------------- 报表管理 : Target Report Controller --------------------
  // 任务汇总表列表
  getTargetTaskList: `${API_URL}target/taskData`,

  // 任务汇总表数据导出
  exportTargetTaskList: `${API_URL}target/taskExport`,

  // 案件结算表列表
  getCaseStatementList: `${API_URL}target/targetData`,

  // 案件结算表数据导出
  exportCaseStatementList: `${API_URL}target/targetExport`,

  // 服务标的汇总表列表
  getCrossAreaTargetList: `${API_URL}target/crossTargetReportList`,
  // 服务标的汇总表数据导出
  exportCrossAreaTargetList: `${API_URL}target/crossTargetReportExport`,
  // 服务标的汇总表列表
  getCorporeSummaryList: `${API_URL}target/getTargetReport`,

  // 服务标的汇总表数据导出
  exportCorporeSummaryList: `${API_URL}target/getTargetReportExport`,

  // 服务任务汇总表列表
  getTaskSummaryList: `${API_URL}target/getTaskReport`,

  // 服务任务汇总表数据导出
  exportTaskSummaryList: `${API_URL}target/getTaskReportExport`,

  // 人员任务报表列表
  getUserTaskList: `${API_URL}target/getUserTaskReport`,

  // 人员任务报表数据导出
  exportUserTaskList: `${API_URL}target/getUserTaskReportExport`,

  // 法院运营周报
  getWeekReport: `${API_URL}target/weakReport`,
  // 法院运营周报导出
  exportWeekReport: `${API_URL}target/weakReportExport`,
  // 入驻法院运营情况分析
  getCourtAnalysis: `${API_URL}target/weakAnalysis`,
  // 入驻法院运营情况导出
  exportCourtAnalysis: `${API_URL}target/weakAnalysisExport`,
  // 拍辅整体运营情况分析表
  getOverallOperation: `${API_URL}target/regionAnalysis`,
  // 导出整体运营情况分析表
  exportOverallOperation: `${API_URL}target/regionAnalysisExport`,
  // 开票信息表
  getInvoiceReportList: `${API_URL}target/invoiceReportList`,
  // 开票信息表-导出
  invoiceReportExport: `${API_URL}target/invoiceReportExport`,
  // 驻点法院服务标的表
  getEnteringCourt: `${API_URL}target/enteringCourt`,
  // 驻点法院服务标的表-导出
  enteringCourtExport: `${API_URL}target/enteringCourtExport`,
  // 省区拓展服务表
  getProvinceServiceCourt: `${API_URL}target/getProvinceServiceCourt`,
  // 省区拓展服务表-导出
  getProvinceServiceCourtExport: `${API_URL}target/getProvinceServiceCourtExport`,

  // -------------------- 案件模块 : Case Controller --------------------
  // 获取案件列表
  getCaseList: `${API_URL}case/caseList`,
  // 获取部门案件列表 departmentCaseList
  getDepartmentCaseList: `${API_URL}case/departmentCaseList`,
  // 导出案件列表
  exportCaseList: `${API_URL}case/caseExport`,
  // 导出案件列表
  exportAuctionList: `${API_URL}case/auction/list/export`,
  // 导出部门案件列表
  getCaseDeptExport: `${API_URL}case/caseDeptExport`,

  // 新增案件-获取法院列表
  getCourtList: `${API_URL}case/selectCourtByDept`,
  // 获取BD
  getTeamBD: `${API_URL}case/selectAllBD`,
  // 获取案件列表
  getJudgeList: `${API_URL}case/selectJudgeByCourtId`,
  // 增加案件
  createCase: `${API_URL}case/addCase`,
  // 编辑案件
  updateCase: `${API_URL}case/updateCase`,
  // 案件详情
  caseDetail: `${API_URL}case/caseDetail`,
  // 创建法官
  createJudge: `${API_URL}case/addJudgeByCourtId`,
  // 上传
  upload: `${API_URL}fileUpload/uploadFile`,
  // 案件下的标的物列表
  targetList: `${API_URL}case/targetList`,
  // 案件附件下载
  caseFileDownLoad: `${API_URL}case/caseDownLoad`,
  // 附件打包
  targetDownLoad: `${API_URL}case/targetDownLoad`,
  // 上拍表下载
  targetFormDownLoad: `${API_URL}case/targetFormDownLoad`,
  // 获取拍卖状态
  auctionStatus: `${API_URL}case/selectAuctionStatus`,
  // 获取城市
  city: `${API_URL}common/cityList`,
  // 获取我的标的列表
  myTargetList: `${API_URL}myTarget/getMyTargetList`,
  // 获取部门标的列表
  departmentTargetList: `${API_URL}myTarget/getDepartmentTargetList`,
  // 我的标的列表导出
  myTargetListExport: `${API_URL}myTarget/myTargetListExport`,
  // 部门列表导出
  departmentTargetListExport: `${API_URL}myTarget/departmentTargetListExport`,
  // 获取所有执行案号

  // 获取url的拍卖信息
  getAuctionByUrl: `${API_URL}case/getAuctionByUrl`,
  // 部门案件删除
  deleteCase: `${API_URL}case/deleteCase`,

  // 查默认是否收费
  selectFreeByCourtId: (courtId: number) =>
    `${API_URL}case/selectFreeByCourtId?courtId=${courtId}`,

  // 查询标的操作日志
  queryTargetRecord: `${API_URL}case/target/logs`,

  // 判断当前用户是否拥有标的编辑权限
  targetOwnPermissionCheck: (targetId: string) =>
    `${API_URL}case/editPermission/${targetId}`,
  // 获取拍卖记录列表
  getAuctionList: `${API_URL}case/auction/list`,

  // -------------------- 标的物模块 : Target Controller --------------------
  // 新增标的物
  addTarget: `${API_URL}case/addTarget`,
  // 获取标的物类型
  targetType: `${API_URL}case/selectTargetType`,
  // 获取标的跨域类型
  getCrossAreaType: (courtId: number, regionId: string) =>
    `${API_URL}case/auctionCross/${regionId}/${courtId}`,
  // 结算记录
  getInvoiceById: (targetId: string | number) =>
    `${API_URL}case/selectInvoiceById/${targetId}`,
  targetMinType: `${API_URL}case/selectTargetMinType`,
  // 删除标的物
  deleteTarget: `${API_URL}case/deleteTarget`,
  // 查询标的物详情
  targetDetail: `${API_URL}case/getTargetDetail`,
  // 查询拍次详情
  auctionDetail: `${API_URL}case/getAuctionDetail`,
  // 编辑标的物基本信息
  updateTarget: `${API_URL}case/saveTarget`,
  // 编辑标的物勘验调查信息
  updateTargetSpecialData: `${API_URL}case/saveProperty`,
  // 编辑标的物拍卖信息
  updateTargetAuctionData: `${API_URL}case/saveUpdataAuction`,
  // 删除标的物拍卖信息
  deleteAuction: `${API_URL}case/deleteAuction`,
  // 标的物看样信息查询
  targetViewInfo: `${API_URL}case/selectViewInfo`,
  // 标的物任务跟踪
  taskTrace: `${API_URL}task/taskTrace`,
  // 标的物任务跟踪查看详情
  taskDetail: `${API_URL}task/taskView`,
  // 任务删除
  taskDelete: `${API_URL}task/taskDelete`,
  // 任务退回
  taskReturn: `${API_URL}task/taskBackSave`,
  // 看样任务编辑
  updateViewTaskTime: `${API_URL}task/updateViewTaskTime`,
  // 任务打包下载
  downloadTaskFile: `${API_URL}task/downloadTaskFile`,
  // 视频下载
  downloadTaskVideoFile: `${API_URL}task/downloadTaskVideoFile`,
  // 标的物资料归档列表
  targetDocumentList: `${API_URL}case/selectDocumentInfo`,
  // 文档下载
  documentDownload: `${API_URL}case/docuDownLoad`,
  // 文档归档
  documentArchive: `${API_URL}case/changeDocument`,
  // 文档撤回归档
  documentArchiveWithdraw: `${API_URL}case/changeStatus`,
  // 获取所有法院信息
  getMyCourt: `${API_URL}case/selectCourtByDept`,

  // 下载批量导入模板
  getTargetTemp: `${API_URL}case/downloadTargetImportTemplate`,

  // 下载批量拍卖导入模板
  getAuctionTemp: `${API_URL}case/downloadAuctionImportTemplate`,

  // 下载批量导入模板
  getInvoiceTemp: `${API_URL}invoice/downloadTemp`,

  // 下载批量导入模板
  getConsultTemp: `${API_URL}consult/downloadTaskImportTemplate`,

  // 导入咨询信息
  importConsultList: `${API_URL}consult/importConsultList`,

  // 导出咨询列表
  consultListExport: `${API_URL}consult/consult/list/export`,

  // 上传模板
  uploadTargetTemp: `${API_URL}case/importTargetList`,

  // 上传拍卖记录
  uploadAuctionList: `${API_URL}case/importAuctionList`,

  // -------------------- 任务模块 : Task Controller --------------------
  // 获取我的任务列表
  getTaskList: `${API_URL}task/taskList`,
  // 获取任务列表
  getTasksList: `${API_URL}task/myTaskList`,
  // 获取部门任务列表
  getDepartmentTaskList: `${API_URL}task/departmentTaskList`,

  // 任务编辑回写
  getTaskInfo: `${API_URL}task/getTaskInfo`,
  // 获取执行人列表
  getViewUserInfo: `${API_URL}task/getViewUserInfo`,
  // 获取法院执行人列表
  getCourtUser: `${API_URL}task/getUserInfo`,
  // 任务新建
  taskCreate: `${API_URL}task/addBatchTask`,
  // 异地任务新建
  taskCrossCreate: `${API_URL}task/addBatchOtherPlaceTask`,
  // 修改是否异地委托
  setCrossEntrust: `${API_URL}myTarget/crossEntrust`,
  // 根据标的物id查询最新的拍次
  selectNewAuction: (targetId: string | number) =>
    `${API_URL}case/selectNewAuction/${targetId}`,
  // 任务编辑暂存
  taskEdit: `${API_URL}task/taskEdit`,
  // 任务提交
  taskSave: `${API_URL}task/taskSave`,
  // 我的任务列表导出
  taskExport: `${API_URL}task/taskExport`,
  // 任务列表导出（BD）
  myTaskExport: `${API_URL}task/myTaskExport`,
  // 任务列表导出（BD）
  departmentTaskExport: `${API_URL}task/departmentTaskExport`,
  // 任务详情
  taskView: `${API_URL}task/taskView`,
  // 标记任务为已读
  taskChecked: `${API_URL}task/taskChecked`,
  // 法院绑定任务
  batchSaveCourtTask: `${API_URL}courtTask/batchSaveCourtTask`,
  // 根据传入的bd或者法院名称查询相关法院的任务数量列表
  selectCourtTaskList: `${API_URL}courtTask/selectCourtTaskList`,
  // 根据法院id查询任务
  selectTaskByCourt: `${API_URL}courtTask/selectTaskByCourt`,
  // 查询我的相关法院的任务数量列表
  myCourtTaskList: `${API_URL}courtTask/myCourtTaskList`,
  // 任务类型列表
  taskType: `${API_URL}common/taskType`,
  // 编辑法院任务
  courtTaskType: `${API_URL}courtTask/batchSaveCourtTask`,

  // -------------------- 规则模块 : Charge Controller --------------------
  // 收费规则列表
  ruleList: `${API_URL}rule/ruleList`,
  // 收费规则新增
  addRule: `${API_URL}rule/addRule`,
  // 收费规则详情新增 无id
  addRuleDetail: `${API_URL}rule/addRuleDetail`,
  // 收费规则详情编辑 有id
  editRuleDetail: `${API_URL}rule/editRuleDetail`,
  // 复制收费规则
  copyRule: `${API_URL}rule/copyRule`,
  // 收费规则中法院列表
  courtList: `${API_URL}rule/courtList`,
  // 收费规则删除
  deleteRule: `${API_URL}rule/deleteRule`,
  // 收费规则按照财产类型分条删除
  deleteRuleDetail: `${API_URL}rule/deleteRuleDetail`,
  // 收费规则编辑
  editRule: `${API_URL}rule/editRule`,
  // 更改绑定
  saveOrUpdateRelation: `${API_URL}rule/saveOrUpdateRelation`,
  // 单条收费规则详情列表
  ruleDetailList: `${API_URL}rule/ruleDetailList`,
  // 签约公司列表
  financeOrgList: `${API_URL}finance/org/list`,
  // 签约公司查询
  getFinanceCompany: (financeId: number) =>
    `${API_URL}finance/detail/org/${financeId}`,
  // 签约公司新增 无id
  addFinanceCompany: `${API_URL}finance/add/org`,
  // 签约公司编辑 有id
  editFinanceCompany: `${API_URL}finance/update/org`,
  // 签约公司状态更改
  changeFinanceCompanyStatus: (financeId: number) =>
    `${API_URL}finance/changeStatus/org/${financeId}`,
  // 提成规则列表
  distributionRuleList: `${API_URL}distribution/v2/list/rule`,
  // 提成规则查询
  getDistributionRule: (distributionId: number) =>
    `${API_URL}distribution/v2/detail/rule/${distributionId}`,
  // 提成规则新增 无id
  addDistributionRule: `${API_URL}distribution/v2/add/rule`,
  // 提成规则编辑 有id
  editDistributionRule: `${API_URL}distribution/v2/update/rule`,
  // 提成规则状态更改
  changeDistributionStatus: (distributionId: number) =>
    `${API_URL}distribution/v2/changeStatus/rule/${distributionId}`,

  // -------------------- 关系模块 : Relation Controller --------------------
  // 新建关联关系
  addRelation: `${API_URL}relation/addRelation`,
  // 新建关联关系
  batchImportService: `${API_URL}relation/importCourtServe`,
  // 法院关联关系列表
  selBDUserList: `${API_URL}relation/selBDUserList`,
  // 法院关联关系列表
  getBDUserInfo: (courtId: number) =>
    `${API_URL}relation/court/detail/${courtId}`,
  editBDUserInfo: `${API_URL}relation/court/setting`,
  // 法院关联关系列表导出
  selBDUserListExport: `${API_URL}relation/selBDUserListExport`,
  // 修改关联关系
  updRelation: `${API_URL}relation/updRelation`,
  // 查找DB
  selUserByNickName: `${API_URL}relation/selUserByNickName`,
  // 查询地区法院
  selClient: `${API_URL}relation/selClient`,
  // 获取财产的收费规则分类
  propertyList: `${API_URL}dictionary/dictionaryGroup`,

  // 消息列表
  messageList: `${API_URL}message/selectMessageList`,
  // 消息详情
  messageDetail: `${API_URL}message/messageDetail`,

  // -------------------- 绩效  到账提成分配模块: Distribution Controller --------------------
  // 提成分配审核（包括初审、复审、归档、关闭）
  auditDistribution: `${API_URL}distribution/auditDistribution`,
  // 提成分配确认
  confirmDistribution: `${API_URL}distribution/confirmDistribution`,
  // 绩效提成管理列表
  getDistributionManageList: `${API_URL}distribution/getDistributionManageList`,
  // 绩效提成管理列表导出
  getDistributionManageListExport: `${API_URL}distribution/getDistributionManageListExport`,
  // 提成分配审核流程记录
  getDistributionRecord: `${API_URL}distribution/getDistributionRecord`,
  // 获取状态下拉列表
  getDistributionStatusList: `${API_URL}distribution/getDistributionStatusList`,
  // 我的绩效提成列表
  getMyDistributionList: `${API_URL}distribution/getMyDistributionList`,
  // 我的绩效提成列表导出
  getMyDistributionListExport: `${API_URL}distribution/getMyDistributionListExport`,
  // 编辑分配明细
  addDistributionDetail: `${API_URL}distribution/addDistributionDetail`,
  // 查询分配明细
  editDistributionDetail: `${API_URL}distribution/editDistributionDetail`,
  // 编辑分配明细
  addDistributionData: `${API_URL}distribution/v2/addDistributionDetail`,
  // 标的提成列表
  commissionListForTarget: `${API_URL}distribution/v2/getWaitRuleDistributionList`,
  // 查询提成的分配明细
  distributionDetailForCommission: `${API_URL}distribution/v2/editDistributionDetail`,
  // 撤回提成分配申请
  withdrawDistribution: `${API_URL}distribution/v2/withdraw`,
  // 初审、复审、归档
  auditAndArchivedDistribution: `${API_URL}distribution/v2/auditDistribution`,
  // 关闭提成分配申请
  closeDistribution: `${API_URL}distribution/v2/close`,
  // 司辅提成管理-申请
  getApplyDistributionList: `${API_URL}distribution/v2/getApplyDistributionList`,
  // 城市备用金列表
  getReserveList: `${API_URL}reward/reserve/list`,
  // 城市备用金-导出
  exportReserveList: `${API_URL}reward/reserve/list/export`,
  // 城市备用金统计
  getReserveSum: `${API_URL}reward/reserve/sum`,
  // 司辅提成管理-权限
  getShowCityReward: `${API_URL}distribution/v2/show/cityReward`,
  // 查询提成分配申请
  getDistributionDetail: (entryId: number) =>
    `${API_URL}distribution/v2/detail/${entryId}`,
  // 司辅提成管理
  selMyDistributionList: `${API_URL}distribution/v2/getMyDistributionList`,
  // 司辅提成管理导出
  exportMyDistributionList: `${API_URL}distribution/v2/getMyDistributionListExport`,
  // 提成分配确认
  confirmMyDistribution: `${API_URL}distribution/v2/confirmDistribution`,
  // 司辅提成管理-审核
  getAuditDistributionList: `${API_URL}distribution/v2/getAuditDistributionList`,
  // 司辅提成管理-审核
  exportAuditDistributionList: `${API_URL}distribution/v2/getAuditDistributionList/export`,
  // 查询提成操作日志
  getDistributionLog: (entryId: number) =>
    `${API_URL}distribution/v2/logs/${entryId}`,
  // 城市奖金列表
  getCityRewardList: `${API_URL}reward/list`,
  // 城市奖金列表导出
  exportCityRewardList: `${API_URL}reward/list/export`,
  // 城市奖金可选城市列表
  getCityRewardCityList: `${API_URL}reward/city/list`,
  // 城市奖金总览
  getCityRewardOverview: `${API_URL}reward/sum/reward`,
  // 城市奖金确认
  selMyCityRewardList: `${API_URL}reward/MyDistribution/list`,
  // 城市奖金确认导出
  exportMyCityRewardList: `${API_URL}reward/getMyDistribution/list/export`,
  // 查询城市奖金
  getCityRewardDetail: (entryId: number) =>
    `${API_URL}reward/detail/${entryId}`,
  // 编辑城市奖金
  addCityRewardData: `${API_URL}reward/add`,
  updateCityRewardData: `${API_URL}reward/update`,
  // 撤回城市奖金
  withdrawCityReward: (rewardId: number) =>
    `${API_URL}reward/withdraw/${rewardId}`,
  // 审核、归档
  auditAndArchivedCityReward: `${API_URL}reward/audit`,
  // 关闭城市奖金申请
  closeCityReward: (rewardId: number) => `${API_URL}reward/close/${rewardId}`,
  // 城市奖金确认
  confirmMyCityReward: `${API_URL}reward/confirm`,
  // 可分配奖金提成列表
  getCityDistributionList: `${API_URL}reward/can/distribution/list`,
  // 可分配奖金人员列表
  getCityMemberList: `${API_URL}reward/user/list`,
  // 查询提成操作日志
  getCityRewardLog: (rewardId: number) => `${API_URL}reward/logs/${rewardId}`,

  // -------------------- 案款分配模块: Distribution Controller --------------------
  // 可分配列表
  getDistributeTargets: `${API_URL}case/distributeCaseMoney/getDistributeTargets`,
  // 可分配列表导出
  exportDistributeTargets: `${API_URL}case/distributeCaseMoney/exportDistributeTargets`,
  // 可分配列表
  getDistributeTargetInfo: (caseId: string) =>
    `${API_URL}case/distributeCaseMoney/targetInfo/${caseId}`,
  // 可分配列表
  addOrUpdateDistributeCaseMoney: `${API_URL}case/distributeCaseMoney/addOrUpdate`,
  // 获取历史分配记录详情
  getDistributeRecord: (id: string) =>
    `${API_URL}case/distributeCaseMoney/distributeRequest/${id}`,
  // 获取历史分配记录详情
  exportDistributeRecord: (id: string) =>
    `${API_URL}case/distributeCaseMoney/export/${id}`,

  // -------------------- 公告检验模块: Distribution Controller --------------------
  // 字典
  getAlarmLevelDic: (
    code: 'AlarmLevel' | 'AlarmType' | 'AlarmRule' | 'alarmTypeAndRole',
  ) => `${BS_API_URL}auctionAlarm/dic/${code}`,
  // 告警列表
  getAuctionAlarmList: `${BS_API_URL}auctionAlarm/list`,

  // 告警数量总计
  getCountByAlarmLevel: `${BS_API_URL}auctionAlarm/countByAlarmLevel`,

  // 告警地图
  getAlarmMap: `${BS_API_URL}auctionAlarm/alarmMap`,

  // 折线图数据获取
  getAlarmMessageSumByTypeAndTimeline: `${BS_API_URL}auctionAlarm/alarmMessageSumByTypeAndTimeline`,

  // 环形图数据获取
  getSummaryByType: `${BS_API_URL}auctionAlarm/summaryByType`,

  // -------------------- 同步数据 : Sync Target Controller --------------------
  // 同步拍卖信息
  synTargetAndAuction: (targetId: string) =>
    `${API_URL}syncTarget/synTargetAndAuction/${targetId}`,

  // 绩效管理导入
  importPerformance: `${API_URL}performanceManage/importPerformance`,

  // 绩效管理导入模板下载
  performanceTemp: `${API_URL}performanceManage/downloadTemp`,

  // 查绩效管理列表
  selectPerformance: `${API_URL}performanceManage/selectPerformance`,

  // 工作台数据总览
  getOverviewData: `${API_URL}workbench/getOverviewData`,

  // 工作台 各平台数据对比
  getPlatFormTargetData: `${API_URL}workbench/getPlatFormTargetData`,

  // 工作台 省份或法院数据范围
  getPermissionRegionOrCourt: `${API_URL}workbench/getPermissionRegionOrCourt`,

  // 工作台报表数据列表
  courtDataList: `${API_URL}workbench/courtDataList`,

  // 工作台报表数据导出
  workbenchListExport: `${API_URL}workbench/listExport`,
}
