export const CaseList = () =>
  import(/* webpackChunkName: "case" */ './CaseList.vue')

export const DepartmentCaseList = () =>
  import(
    /* webpackChunkName: "case" */ './department-case/DepartmentCaseList.vue'
  )

export const MyTargetList = () =>
  import(/* webpackChunkName: "case" */ './MyTargetList.vue')

export const DepartmentTargetList = () =>
  import(
    /* webpackChunkName: "case" */ './department-target/DepartmentTargetList.vue'
  )

export const CaseDetail = () =>
  import(/* webpackChunkName: "case" */ './CaseDetail.vue')

export const AuctionList = () =>
  import(/* webpackChunkName: "case" */ './AuctionList.vue')

export const myAuctionDetail = () =>
  import(/* webpackChunkName: "case" */ './AuctionDetailForm.vue')
